import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';
import ExternalLink from 'components/ExternalLink/index';
import './index.css';

export default class App extends Component {
  componentDidMount() {
    ReactGA.pageview('/about');
  }

  render() {
    return (
      <>
        <br/>

        <Container>
          <Row>
            <Col>
              <h4>About</h4>
              <hr/>
              <p>
                <strong>NLU Sense</strong> is a resource aiming to provide glanceable view of
                <ExternalLink
                  href="https://en.wikipedia.org/wiki/Natural-language_understanding"
                  text="Natural Language Understanding"/>
                field of computer science.
              </p>
              <p>
                The hierarchy of NLU tasks, areas of research and related technologies is being manually maintained.
              </p>

              <h4>Beyond Now</h4>
              <hr/>
              <p>
                Moving forward the goal would be to accompany high-level hierarchy with in depth details associated with
                each task represented in here.
                The volume of updates produced by the community in this space is beyond control
                (which is a great thing and the reason why this service exists).
                The plan is to prioritize and cover the following (and potentially other) areas with automated
                scraping solutions (powered by NLU tech?).
              </p>
              <p>
                <ul>
                  <li>Paper labeling by category</li>
                  <li>Dataset and challenge classification by problem</li>
                  <li>SoTA extraction and leaderboard population</li>
                  <li>Tagging open source contributions</li>
                  <li>Tracking managed offerings</li>
                </ul>
              </p>

              <h4>
                Data
              </h4>
              <hr/>
              <p>
                Data backing the portal is available for download and further analysis.
              </p>
              <p>
                <ul>
                  <li>
                    <ExternalLink
                      href="https://nlusense.com/data/nlu.json"
                      text="NLU Graph Data"/>
                  </li>
                </ul>

              </p>
              <p>
                <aside>
                  The format of the above mentioned artifacts is not final and can be changed along with any update.
                </aside>
              </p>

              <h4>License</h4>
              <hr/>
              <p>
                The content of the resource is licensed under
                <ExternalLink
                  href="https://creativecommons.org/licenses/by-sa/4.0/"
                  text="CC BY-SA 4.0"/>.
              </p>

              <h4>Citation</h4>
              <hr/>
              <p>
                Please provide the following reference when publishing or otherwise referring to materials
                published on the website.
              </p>
              <p>
                <aside>
                  Pushkin, E., et al. ({(new Date()).getFullYear()}). NLU Sense. Retrieved from https://nlusense.com
                </aside>
              </p>

              <h4>Contribution</h4>
              <hr/>
              <p>
                Contributions and recommendation are more than welcome!
              </p>
              <p>
                Check out related
                <ExternalLink
                  href="https://github.com/nlusense/nlusense-data"
                  text="GitHub repository"/>
                for details regarding data correction and feature request submission.
              </p>
              <p>
                <a className="github-button" href="https://github.com/nlusense/nlusense-data/issues" data-icon="octicon-issue-opened" data-show-count="true" aria-label="Issue nlusense/nlusense-data on GitHub">Issue</a>
              </p>

              <h4>Maintainer</h4>
              <hr/>
              <p>
                <strong>NLU Sense</strong> is maintained by <strong>Egor Pushkin</strong>.
              </p>
              <p>
                <a href="https://www.linkedin.com/in/egorpushkin/"
                  target="_blank" rel="noopener noreferrer">
                  https://www.linkedin.com/in/egorpushkin
                </a> <br/>
                <a href="https://twitter.com/egorpushkin"
                  target="_blank" rel="noopener noreferrer">
                  https://twitter.com/egorpushkin
                </a> <br/>
                <a href="https://github.com/egorpushkin"
                  target="_blank" rel="noopener noreferrer">
                  https://github.com/egorpushkin
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
