import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import ReactGA from 'react-ga';

export default class DetailsLinks extends Component {
  sections = [
    {
      name: "General Info",
      label: "general",
      general: true,
      links: [
        {
          name: "Google Search",
          label: "google_search",
          href: "https://www.google.com/search?q="
        },
        {
          name: "Wikipedia Search",
          label: "wikipedia_search",
          href: "https://en.wikipedia.org/wiki/Special:Search?go=Go&ns0=1&search="
        }
      ]
    }, {
      name: "Papers",
      label: "papers",
      general: false,
      links: [
        {
          name: "Google Scholar",
          label: "google_scholar",
          href: "https://scholar.google.com/scholar?q="
        }, {
          name: "Semantic Scholar",
          label: "semantic_scholar",
          href: "https://www.semanticscholar.org/search?sort=relevance&q="
        }, {
          name: "Papers with Code",
          label: "papers_with_code",
          href: "https://paperswithcode.com/search?q="
        }
      ]
    }, {
      name: "Code",
      label: "code",
      general: false,
      links: [
        {
          name: "GitHub",
          label: "github",
          href: "https://github.com/search?q="
        }
      ]
    }, {
      name: "Datasets",
      label: "datasets",
      general: false,
      links: [
        {
          name: "Google Dataset Search",
          label: "google_dataset_search",
          href: "https://toolbox.google.com/datasetsearch/search?query="
        }, {
          name: "Kaggle Dataset Search",
          label: "kaggle_dataset_search",
          href: "https://www.kaggle.com/datasets?search="
        }, {
          name: "Elsevier Data Search",
          label: "elsevier_data_search",
          href: "https://datasearch.elsevier.com/#/search/"
        }, {
          name: "Wolfram Data Repository",
          label: "wolfram_data_repository",
          href: "https://datarepository.wolframcloud.com/search/?i="
        }
      ]
    }, {
      name: "Progress",
      label: "progress",
      general: false,
      links: [
        {
          name: "NLP Progress",
          label: "nlp_progress",
          href: "http://www.google.com/search?btnI&q=site:nlpprogress.com%20"
        }
      ]
    }
  ]

  handleClick = (section, link) => {
    ReactGA.event({
      category: "external_link",
      action: section.label + "_" + link.label
    });
  }

  render() {
    const vertex = this.props.vertex;

    var linkSectionList = (vertex["links"] || "").split(",");
    var linkSectionSet = new Set();
    for ( var linkSection of linkSectionList ) {
      linkSectionSet.add(linkSection);
    }
    var searchTerm = encodeURIComponent(vertex.search_term || vertex.full_name);

    var sections = [];
    for (let [sectionIndex, section] of this.sections.entries()) {
      if ( !linkSectionSet.has("all") && !linkSectionSet.has(section.label) ) {
        continue;
      }

      var links = [];
      for (let [linkIndex, link] of section.links.entries()) {
        links.push(
          <span key={linkIndex} className="external-link">
            <a href={link.href + searchTerm}
              onClick={() => this.handleClick(section, link)}
              target="_blank" rel="noopener noreferrer">{link.name}</a>
          </span>
        );
      }
      sections.push(
        <div key={sectionIndex}>
          <Card.Text as="h6">{section.name}</Card.Text>
          <p>
            {links}
          </p>
        </div>
      );
    }

    return (
      <>
        {sections.length > 0 &&
          <>
            {sections}
          </>
        }
      </>
    );
  }
}
