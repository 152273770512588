import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container, Navbar, Nav } from 'react-bootstrap';
import Main from '../Main/index';
import About from '../About/index';
import StickyFooter from '../../components/StickyFooter/index';
import './index.css';

export default class App extends Component {
  render() {
    return (
      <Router>
        <Navbar bg="light" variant="light" expand="lg" className="shadow-sm rounded">
          <Container>
            <Navbar.Brand href="/">NLU Sense</Navbar.Brand>
            <Navbar className="justify-content-end">
              <Nav.Link href="/about" className="text-secondary">About</Nav.Link>
            </Navbar>
          </Container>
        </Navbar>

        <Route exact path={["/", "/v/:id"]} component={Main} />
        <Route path="/about" component={About} />

        <StickyFooter/>
      </Router>
    );
  }
}
