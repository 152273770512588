import React from 'react';
import './index.css';

function StickyFooter() {
  return (
    <div className="navbar fixed-bottom">
      <div className="container">
        &nbsp;

        <div className="float-right share-panel shadow-sm rounded">
          <div className="share-button share-button-facebook fb-like"
            data-href="https://nlusense.com/"
            data-width=""
            data-layout="button"
            data-action="like"
            data-size="small"
            data-show-faces="false"
            data-share="true">
          </div>

          <div className="share-button share-button-linkedin">
            <script type="IN/Share" data-url="https://nlusense.com"></script> &nbsp;
          </div>

          <div className="share-button">
            <a className="twitter-share-button"
              href="https://twitter.com/intent/tweet?text=Check%20out%20the%20state%20of%20NLP%20at%20">
            Tweet</a> &nbsp;
          </div>

          <div className="share-button">
            <a className="github-button"
              href="https://github.com/nlusense/nlusense-data"
              data-icon="octicon-star"
              data-show-count="false"
              aria-label="Star nlusense/nlusense-data on GitHub">Star
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyFooter;
