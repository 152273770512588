import React, { Component } from 'react';
import vis from 'vis/dist/vis.min.js';
import 'vis/dist/vis.min.css';
import './index.css';
import ReactGA from 'react-ga';

export default class MapGraphView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rendered: false
    };

    this.uiVertexIndex = new Map();
    this.allVertexIds = [];
    this.network = null;
  }

  componentWillReceiveProps(props) {
    // Nothing to do if data is not loaded yet
    if ( null == props.app.data ) {
      return;
    }

    // Build network if necessary
    if ( !this.state.rendered ) {
      this.renderGraph(props);
    }

    // Honor selection dictated by the parent
    if ( props.app.currentVertex ) {
      const nodeSelection = [props.app.currentVertex.id];
      this.network.selectNodes(nodeSelection);
    }
  }

  renderGraph(props) {
    var nodes = new vis.DataSet([]);
    var edges = new vis.DataSet([]);

    // create a network
    var container = document.getElementById('graph');
    var data = {
      nodes: nodes,
      edges: edges
    };
    var options = {
      physics: {
        enabled: true,
        stabilization: {
          enabled: true,
          fit: true
        }
      },
      layout: {
        randomSeed: 2
      },
      nodes: {
        shape: "box",
        font: {
          multi: true
        },
        margin: { top: 7, bottom: 7, left: 12, right: 12 },
        color: {
          background: "#ffffff",
          border: "#bbbbbb"
        },
        shadow: {
          enabled: true,
          color: '#eeeeee',
          x: 5,
          y: 5,
          size: 2
        }
      },
      edges: {
        shadow: {
          enabled: false
        },
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 0.7
          }
        }
      }
    };
    this.network = new vis.Network(container, data, options);

    this.network.on("select", this.onSelectNode);

    var nodeStyles = new Map();
    nodeStyles.set("Domain", {
      color: {
        background: "#bbbbbb",
      }
    });
    nodeStyles.set("Subdomain", {
      color: {
        background: "#dddddd",
      }
    });
    nodeStyles.set("Node", {
      color: {
        background: "#ffffff",
      }
    });

    for (var vertex of props.app.data["vertices"]) {
      var style = nodeStyles.get(vertex["class"]);
      var label
        = vertex["full_name"] === vertex["short_name"]
        ? "<b>" + vertex["full_name"] + "</b>"
        : "<b>" + vertex["full_name"] + "</b>\n" + vertex["short_name"];
      var uiVertex = {
        id: vertex["id"],
        label: label,
        color: style.color
      }
      nodes.add(uiVertex);

      this.allVertexIds.push(vertex["id"]);
      this.uiVertexIndex.set(vertex["id"], uiVertex);
      uiVertex.vertex = vertex;

      vertex.parent = null;
      vertex.children = [];
    }

    for (var edge of props.app.data["edges"]) {
      var toUiVertex = this.uiVertexIndex.get(edge["to_vertex_id"]);
      var fromUiVertex = this.uiVertexIndex.get(edge["from_vertex_id"]);
      var uiEdge = {
        from: fromUiVertex.vertex["id"],
        to: toUiVertex.vertex["id"]
      };
      edges.add(uiEdge);

      // NOTE: This only holds true while all links are top down.
      toUiVertex.vertex.parent = fromUiVertex.vertex;
      fromUiVertex.vertex.children.push(toUiVertex.vertex);
    }

    this.setState({
      rendered: true
    });
  }

  onSelectNode = (params) => {
    for (var nodeId of params.nodes) {
      var uiVertex = this.uiVertexIndex.get(nodeId);
      this.applyNodeSelection(uiVertex, true);
      break;
    }
  }

  applyNodeSelection(uiVertex, logEvent) {
    // Update app state
    this.props.app.updateCurrentVertex(uiVertex.vertex);

    // Log GA event
    if ( logEvent ) {
      ReactGA.event({
        category: "active_node",
        action: uiVertex.vertex["full_name"]
      });
    }
  }

  render() {
    return (
      <div id="graph" />
    );
  }
}
