import React, { Component } from 'react';

export default class ExternalLink extends Component {
  render() {
    return (
      <>
        &nbsp;
        <a href={this.props.href}
          target="_blank" rel="noopener noreferrer">
          {this.props.text}
        </a>
        &nbsp;
      </>
    );
  }
}
