import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Card, Breadcrumb, Badge } from 'react-bootstrap';
import Octicon, {Link as LinkIcon} from '@github/octicons-react';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import DetailsLinks from './links'
import './index.css'

export default class DetailsPanel extends Component {
  render() {
    const vertex = this.props.app.currentVertex;
    if ( null == vertex ) {
      return (<></>)
    }

    // Generate deep link to the node
    var thisPagePath = this.props.app.vertexToAbsolutePath(vertex);

    // Populate breadcrumbs
    var currentVertex = vertex.parent;
    var breadcrumbs = [];
    while ( null != currentVertex ) {
      breadcrumbs.push(
        <Breadcrumb.Item key={currentVertex.short_name} active>
          <Link to={this.props.app.vertexToRelativePath(currentVertex)}>
            {currentVertex.short_name}
          </Link>
        </Breadcrumb.Item>
      );
      currentVertex = currentVertex.parent;
    }
    breadcrumbs = breadcrumbs.reverse();

    // Populate subtasks (if any)
    var subtasks = [];
    for (var child of vertex.children) {
      subtasks.push(
        <span key={child.full_name}>
          <Link to={this.props.app.vertexToRelativePath(child)}>
            <Badge variant="secondary">{child.full_name}</Badge>
          </Link>
          &nbsp;
        </span>
      );
    }

    // Populate synonyms (if any)
    var synonyms = [];
    for (var synonym of vertex.synonyms || []) {
      synonyms.push(
        <span key={synonym}>
          <Badge variant="secondary">{synonym}</Badge>
          &nbsp;
        </span>
      );
    }

    return (
      <Card border="light" className="shadow-sm rounded">
        <Card.Body>
          <Card.Title>{vertex.full_name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {vertex.full_name !== vertex.short_name &&
              <>
                {vertex.short_name} &nbsp;
              </>
            }

            <CopyToClipboard text={thisPagePath}>
              <span>
                <Octicon icon={LinkIcon}/>
              </span>
            </CopyToClipboard>
          </Card.Subtitle>

          {breadcrumbs.length > 0 &&
            <>
              <Card.Text as="h6">Path</Card.Text>
              <Breadcrumb>{breadcrumbs}</Breadcrumb>
            </>
          }

          {subtasks.length > 0 &&
            <>
              <Card.Text as="h6">Sections</Card.Text>
              <Card.Text>
                {subtasks}
              </Card.Text>
            </>
          }

          {vertex.overview &&
            <>
              <Card.Text as="h6">Overview</Card.Text>
              <Card.Text>
                {vertex.overview}
              </Card.Text>
            </>
          }

          {synonyms.length > 0 &&
            <>
              <Card.Text as="h6">Synonyms</Card.Text>
              <Card.Text>
                {synonyms}
              </Card.Text>
            </>
          }

          <DetailsLinks vertex={vertex}/>
        </Card.Body>
      </Card>
    );
  }
}
