import React, { Component } from 'react';
import { Container, Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Octicon, {GitMerge, ListUnordered, ScreenNormal, ScreenFull} from '@github/octicons-react';
import MapGraphView from '../../components/MapGraphView/index'
import MapTreeView from '../../components/MapTreeView/index'
import DetailsPanel from '../../components/DetailsPanel/index'
import ReactGA from 'react-ga';
import axios from 'axios';
import './index.css';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.vertexPrefix = "/v/";

    this.state = {
      mapView: "graph",
      mapMode: "normal",
      data: null,
      currentVertex: null,
      updateCurrentVertex: this.updateCurrentVertex,
      vertexToRelativePath: this.vertexToRelativePath,
      vertexToAbsolutePath: this.vertexToAbsolutePath
    };

    // Listen to navigation changes as means of toggling between vertexes.
    this.props.history.listen(this.handleNavChange);
  }

  handleNavChange = (location, action) => {
    // Confirm pathname points at a vertex
    if ( !location.pathname.startsWith(this.vertexPrefix) ) {
      return;
    }

    // Extract vertex id
    const cleanVertexId = location.pathname.substring(this.vertexPrefix.length);

    // Apply selection
    this.selectVertexByCleanId(cleanVertexId);
  }

  selectVertexByCleanId = (cleanVertexId) => {
    const vertexId = "#" + cleanVertexId;

    // Find vertex to be selected
    var currentVertex = null;
    for (const vertex of this.state.data["vertices"]) {
      if (vertexId === vertex.id) {
        currentVertex = vertex;
        break;
      }
    }
    if ( !currentVertex ) {
      return;
    }

    // Apply selection
    this.setState({
      currentVertex: currentVertex
    });
  }

  handleMapViewChange = (value, event) => {
    this.setState({
      mapView: value
    });
  }

  handleMapModeChange = (value, event) => {
    this.setState({
      mapMode: value
    });
  }

  updateCurrentVertex = (vertex) => {
    const pagePath = this.vertexToRelativePath(vertex);
    this.props.history.push(pagePath);
  }

  vertexToRelativePath = (vertex) => {
    const cleanVertexId = vertex.id.substring(1);
    const pagePath = this.vertexPrefix + cleanVertexId;
    return pagePath;
  }

  vertexToAbsolutePath = (vertex) => {
    var pagePath = window.location.protocol + "//" + window.location.host + this.vertexToRelativePath(vertex);
    return pagePath;
  }

  componentWillMount() {
    axios.get("/data/nlu.json")
      .then( response => {
        // Remember data
        this.setState({
          data: response.data
        });

        // Apply selection if any is requested
        if ( this.props.match.params.id ) {
          this.selectVertexByCleanId(this.props.match.params.id);
        }
        // Otherwise select root node
        else {
          for (const vertex of response.data["vertices"]) {
            if ( vertex["is_root"] ) {
              this.setState({
                currentVertex: vertex
              });
              break;
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      }
    );
  }

  componentDidMount() {
    ReactGA.pageview('/home');
  }

  render() {
    return (
      <>
        <br/>

        <Container>
          <Row>
            <Col>
              <span>
                <ToggleButtonGroup
                  type="radio"
                  name="mapView"
                  size="sm"
                  defaultValue={this.state.mapView}
                  onChange={this.handleMapViewChange}>
                  <ToggleButton value={"graph"} variant="light">
                    <Octicon icon={GitMerge}/>
                  </ToggleButton>
                  <ToggleButton value={"tree"} variant="light">
                    <Octicon icon={ListUnordered}/>
                  </ToggleButton>
                </ToggleButtonGroup>
              </span>

              {"graph" === this.state.mapView &&
                <>
                  <span className="mode-toggle">
                    <ToggleButtonGroup
                      type="radio"
                      name="mapMode"
                      size="sm"
                      defaultValue={this.state.mapMode}
                      onChange={this.handleMapModeChange}>
                      <ToggleButton value={"normal"} variant="light">
                        <Octicon icon={ScreenNormal}/>
                      </ToggleButton>
                      <ToggleButton value={"full"} variant="light">
                        <Octicon icon={ScreenFull}/>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </span>
                </>
              }

              <div className="float-right main-quote">
                “Language shapes the way we think, and determines what we can think about.”<br/>
                ― Benjamin Lee Whorf
              </div>
            </Col>
          </Row>
        </Container>

        <br/>

        <div className={this.state.mapMode === "normal" || this.state.mapView === "tree" ? "container layout-content" : "layout-content"}>
          <Row className={this.state.mapMode === "normal" || this.state.mapView === "tree" ? "layout-all-height" : "layout-all-height layout-full"}>
            <Col className="layout-parent">
              <div className={this.state.mapView === "graph" ? "layout-graph " : "hidden"}>
                <MapGraphView app={this.state}/>
              </div>
              <div className={this.state.mapView === "tree" ? "layout-graph " : "hidden"}>
                <MapTreeView app={this.state}/>
              </div>
              <div className="layout-details">
                <DetailsPanel app={this.state}/>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
