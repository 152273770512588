import React, { Component } from 'react';
import Octicon, {ChevronLeft, ChevronRight} from '@github/octicons-react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import './index.css';

export default class MapTreeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rendered: false,
      selectedNode: null,
      searchString: "",
      searchFocusIndex: 0,
      searchFoundCount: null,
      searchMatches: null,
      treeData: [],
    };

    this.treeNodeIndex = null;
  }

  componentWillReceiveProps(props) {
    // Nothing to do if data is not loaded yet
    if ( null == props.app.data ) {
      return;
    }

    // Build network if necessary
    if ( !this.state.rendered ) {
      this.renderTree(props);
    }

    // Honor selection dictated by the parent
    if ( props.app.currentVertex ) {
      var selectedNode = this.treeNodeIndex.get(props.app.currentVertex.id);
      this.setState({
        selectedNode: selectedNode
      });
    }
  }

  renderTree(props) {
    this.treeNodeIndex = new Map();

    for (var vertex of props.app.data["vertices"]) {
      const node = {
        title: vertex["full_name"],
        subtitle: vertex["short_name"],
        expanded: false,
        children: [],
        vertex: vertex
      };
      this.treeNodeIndex.set(vertex["id"], node);
      vertex.parent = null;
      vertex.children = [];
    }

    for (var edge of props.app.data["edges"]) {
      var childTreeNode = this.treeNodeIndex.get(edge["to_vertex_id"]);
      if (childTreeNode.expanded) {
        continue;
      }
      var parentTreeNode = this.treeNodeIndex.get(edge["from_vertex_id"]);
      parentTreeNode.children.push(childTreeNode);
      childTreeNode.expanded = true;

      childTreeNode.vertex.parent = parentTreeNode.vertex;
      parentTreeNode.vertex.children.push(childTreeNode.vertex);
    }

    var treeData = [];
    for (var [,rootNode] of this.treeNodeIndex) {
      if (rootNode.expanded) {
        continue;
      }
      rootNode.expanded = true;
      treeData.push(rootNode);
    }

    this.setState({
      treeData: treeData,
      rendered: true
    });
  }

  onNodeClick(node) {
    // Cancel search
    this.setState({
      searchString: ""
    });

    // Update node selection
    this.onSelectNode(node);
  }

  onSelectNode(node) {
    // Remember node locally
    this.setState({
      selectedNode: node
    });

    // Update parent
    this.props.app.updateCurrentVertex(node.vertex);
  }

  generateNodeProps = (rowInfo) => {
    var props = {
      onClick: () => this.onNodeClick(rowInfo.node)
    };

    if ( this.state.selectedNode === rowInfo.node ) {
      props.className = "rst__rowSearchFocus";
    }

    return props;
  }

  handleSearchOnChange = e => {
    this.setState({
      searchString: e.target.value,
    });
  };

  selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    const newSearchFocusIndex = (searchFoundCount + searchFocusIndex - 1) % searchFoundCount;
    this.setState({
      searchFocusIndex: newSearchFocusIndex
    });

    if ( this.state.searchFoundCount > 0 ) {
      this.onSelectNode(this.state.searchMatches[newSearchFocusIndex].node);
    }
  };

  selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    const newSearchFocusIndex = (searchFocusIndex + 1) % searchFoundCount;
    this.setState({
      searchFocusIndex: newSearchFocusIndex
    });

    if ( this.state.searchFoundCount > 0 ) {
      this.onSelectNode(this.state.searchMatches[newSearchFocusIndex].node);
    }
  };

  searchMethod = ({ node, searchQuery }) => {
    if ( !searchQuery ) {
      return false;
    }

    var lowerSearchQuery = searchQuery.toLowerCase();

    return (
      node.title.toLowerCase().indexOf(lowerSearchQuery) > -1
      || node.subtitle.toLowerCase().indexOf(lowerSearchQuery) > -1
    );
  }

  searchFinishCallback = (matches) => {
    const newSearchFocusIndex = matches.length > 0 ? this.state.searchFocusIndex % matches.length : 0;
    this.setState({
      searchFoundCount: matches.length,
      searchFocusIndex: newSearchFocusIndex,
      searchMatches: matches,
    });

    if ( matches.length > 0 ) {
      this.onSelectNode(matches[newSearchFocusIndex].node);
    }
  }

  render() {
    const {
      treeData,
      searchString,
      searchFocusIndex,
      searchFoundCount,
    } = this.state;

    return (
      <>
        <div className="tree-content">
          <div>
            <div className="input-group input-group-sm">
              <input
                type="text" className="form-control"
                placeholder="Search..." aria-label="Search..." aria-describedby="tree-search"
                onChange={this.handleSearchOnChange}
                value={searchString}/>
              {searchFoundCount > 0 &&
                <div className="input-group-append">
                  <span className="input-group-text">
                    {searchFoundCount ? (searchFocusIndex+1) : 0} / {searchFoundCount}
                  </span>
                  <button className="btn btn-sm btn-outline-secondary" type="button" onClick={this.selectPrevMatch}>
                    <Octicon icon={ChevronLeft}/>
                  </button>
                  <button className="btn btn-sm btn-outline-secondary" type="button" onClick={this.selectNextMatch}>
                    <Octicon icon={ChevronRight}/>
                  </button>
                </div>
              }
            </div>
          </div>

          <br/>

          <div className="tree">
            <SortableTree
              treeData={treeData}
              onChange={treeData => this.setState({ treeData })}
              canDrag={false}
              generateNodeProps={this.generateNodeProps}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={this.searchFinishCallback}
              searchMethod={this.searchMethod}
            />
          </div>
        </div>
      </>
    );
  }
}
